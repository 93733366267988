import React, { lazy } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import config from "../config";
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import PrivateRoute from "../components/PrivateRoute";

// Auth Pages
const LoginPage = Loadable(lazy(() => import("../views/auth/Login")));
const RegisterPage = Loadable(lazy(() => import("../views/auth/Register")));

const HomePage = Loadable(lazy(() => import("../views/HomePage/home.page")));
const ProspectPage = Loadable(
  lazy(() => import("../views/ProspectPage/prospect.page"))
);

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  return (
    <Routes>
      {/* Main Routes */}
      <Route path="/" element={<Navigate replace to={config.defaultPath} />} />
      <Route
        path="app"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route path="home" element={<HomePage />} />
      </Route>

      {/* Auth Routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/prospect" element={<ProspectPage />} />
    </Routes>
  );
};

export default AppRoutes;
