import HomeIcon from "@mui/icons-material/Home";

export const MenuItemsList = [
  {
    id: "HOME",
    title: "Home",
    url: "/app/home",
    icon: HomeIcon,
  },
];

// Azure Bot
export const AZMenuItems = [
  {
    id: "bot",
    title: "Query Mode",
    url: "/query-mode",
    isChild: false,
    childList: [],
  },
];

export const MenuItems = {
  bot: AZMenuItems,
};
