export const compStyleOverride = (theme) => {
  return {
    MuiPaper: {
      // Component Name
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        //Rule Name
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: theme.customization.borderRadius + "px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          padding: "8px 12px",
          fontSize: "14px",
          lineHeight: "21px",
        },
        sizeMedium: {
          padding: "10px 20px",
          fontSize: "15px",
          lineHeight: "22px",
        },
        sizeLarge: {
          padding: "15px 20px",
          fontSize: "18px",
          lineHeight: "25px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          //borderColor: `#e7ddd1`, //#efe5d8, e7ddd1, ebe3db
          //padding: "18px 15px",
          "& a": {
            color: theme.colors.grey300,
            display: "inline-block",
            verticalAlign: "middle",
            textDecoration: "none !important",
            "& svg": {
              width: "15px",
              height: "15px",
              marginLeft: "2px",
              marginTop: "-2px",
              verticalAlign: "middle",
              //color: "#ffc774",
              color: theme.colors.primaryMain,
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${theme.customization.borderColor}`,
        },
        selectLabel: {
          marginTop: 0,
          marginBottom: 0,
          color: theme.colors.grey100,
        },
        displayedRows: {
          marginTop: 0,
          marginBottom: 0,
          color: theme.colors.grey100,
        },
        toolbar: {
          paddingTop: "5px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: "15px 6px",
          backgroundColor: theme.colors.grey900,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          // color: theme.darkTextPrimary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            "&:hover": {
              backgroundColor: theme.menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: theme.menuSelected,
            },
          },
          "&:hover": {
            backgroundColor: theme.menuSelectedBack,
            // color: theme.menuSelected,
            "& .MuiListItemIcon-root": {
              color: theme.menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        // root: {
        //   color: theme.darkTextPrimary,
        //   minWidth: "36px",
        // },
      },
    },
    // MuiListItemText: {
    //   styleOverrides: {
    //     primary: {
    //       color: theme.textDark,
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        input: {
          //color: theme.colors.paper,
          "&::placeholder": {
            color: theme.textDark,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: theme.colors.grey800,
          borderRadius: theme.customization.borderRadius + "px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors.grey800,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors.grey800,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: theme.colors.grey800,
          padding: "15.5px 14px",
          borderRadius: theme.customization.borderRadius + "px",
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: theme.customization.borderRadius + "px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          "&:before": {
            borderBottom: "0 !important",
          },
          "&:after": {
            borderBottom: "0 !important",
          },
        },
      },
    },
    MuiError: {
      styleOverrides: {
        root: {
          backgroundColor: "green !important",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme.colors.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors.primaryMain,
          background: theme.colors.primary100,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors.grey800,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: "25px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "25px",
          lineHeight: "32px",
          fontWeight: 500,
          color: "#111111",
          //color: "#e9e9e9",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: "transparent",
          // maxWidth: "650px",
          // borderRadius: "20px",
          // backgroundColor: "#efede4", //#e1ded1
          //backgroundColor: "rgba(0,0,0,0.5)",
          //backgroundColor: "#222",
        },
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.9)",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "25px",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "#777777",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          position: "relative",
        },
        tag: {
          maxWidth: "100px",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          //background: "rgba(0, 0, 0, 0.9)",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: "#a5d6a7",
          color: theme.colors.grey900,
          "& .MuiAlert-icon": {
            color: "#016606",
          },
        },
        standardError: {
          backgroundColor: theme.colors.errorLight,
          color: "#111111",
          "& .MuiAlert-icon": {
            color: "#990e04",
          },
        },
        standardWarning: {
          backgroundColor: theme.colors.warningLight,
          color: theme.colors.grey900,
          "& .MuiAlert-icon": {
            color: "#af8404",
          },
        },
        standardInfo: {
          backgroundColor: theme.colors.infoLight,
          color: theme.colors.grey900,
          "& .MuiAlert-icon": {
            color: "#005378",
          },
        },
      },
    },
  };
};
