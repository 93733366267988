const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  basename: "",
  defaultPath: "/app/home",
  defaultPathID: "HOME",
  fontFamily: `'Ubuntu', sans-serif`,
  fontSize: 14,
  backgroundColor: "#111111",
  borderColor: "#111111",
  borderRadiusLarge: 15,
  borderRadius: 8,
  borderRadiusSmall: 4,
  gridSpacing: 3,
  gridSpacingSm: 2,
  avatars: {
    bot: {
      src: "https://www.ongil.ai/wp-content/uploads/2022/07/cropped-favicon-512x512-1-192x192.png",
      alt: "Octo",
    },
    human: {
      src: "https://www.ongil.ai/wp-content/themes/ongil/images/user_chat.png",
      alt: "User",
    },
  },
  initialMsg: {
    author: "bot",
    body: "Hello, I am Octo, Your analytic assistant. Please enter your query",
  },
};

export default config;
