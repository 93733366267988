import React, { useEffect } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  privateApiGET,
  privateApiPOST,
  wrapBaseURL,
} from "../../components/PrivateRoute";
import Api from "../../components/Api";
import {
  setActivePageID,
  disableInitialLoading,
  setProfileData,
  setBotSettingsData,
} from "../../redux/app/appSlice";
import { MenuItemsList } from "../../menu-items";
import {
  resetQuerySlice,
  setUserTypeAsAdvisor,
  setMicroBotOpened,
  setQueryChannelInfo,
} from "../../views/MicroBot/reduxComp/query/querySlice";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  mainSection: {
    padding: "125px 30px 30px 310px",
    transition: "padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    "&.noSidebar": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "30px",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
  },
}));

const MainLayout = ({}) => {
  const { isSidebarOpened, initialAppLoading } = useSelector(
    (state) => state.app
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Profile API comes here & initial loading and settting up pages happen here
  const handleFetchProfileData = () => {
    privateApiGET(wrapBaseURL(Api.profile))
      .then((response) => {
        const { data, status } = response;
        console.log("Profile Data ---> ", data);
        if (status === 200) {
          const { group } = data?.data;
          // Checking Logged user is WA or Customer
          if (group && group.length && group[0] === "UserType_Advisory") {
            dispatch(setUserTypeAsAdvisor(true));
            dispatch(setMicroBotOpened(true));
          } else {
            dispatch(setUserTypeAsAdvisor(false));
          }
          dispatch(disableInitialLoading(false));
          dispatch(setProfileData(data?.data));
        } else {
          dispatch(disableInitialLoading(false));
        }
      })
      .catch((error) => {
        dispatch(disableInitialLoading(false));
        console.log("Profile API Error ----> ", error);
        localStorage.removeItem("token");
        dispatch(resetQuerySlice());
        navigate("/login");
      });
  };

  // Bot Settings API to get backend URL
  const handleBotSettingsData = () => {
    privateApiPOST(wrapBaseURL(Api.botSettings), { key: "backend_url" })
      .then((response) => {
        const { data, status } = response;
        console.log("BOT Settings ---> ", data);
        if (status === 200) {
          dispatch(setBotSettingsData(data?.data));
        }
      })
      .catch((error) => {
        console.log("Bot Settings API Error ----> ", error);
        dispatch(setBotSettingsData({}));
      });
  };

  // Channel Session
  const handleCreateChannelInfo = () => {
    const token = localStorage.getItem("token");
    axios({
      method: "POST",
      url: wrapBaseURL(Api.channelInfo),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      data: { bot_query_mode: "wealthAdvisor" },
    })
      .then((res) => {
        const { data, status } = res;
        //console.log("Create channel data ---> ", data);
        if (status === 200) {
          dispatch(setQueryChannelInfo(data?.data));
        }
      })
      .catch((error) => {
        console.log("Create channel error ---> ", error.response);
        handleLogoutRedirect();
        //dispatch(setQueryChannelInfo(null));
      });
  };

  // Redirect Bot to Login
  const handleLogoutRedirect = () => {
    const token = localStorage.getItem("token");
    axios({
      method: "POST",
      url: wrapBaseURL(Api.logout),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log("Logout API RESPONSE ---> ", res);
        navigate("/login");
        localStorage.removeItem("token");
        dispatch(resetQuerySlice());
      })
      .catch((error) => {
        console.log("Logout API ERROR ---> ", error.response);
        localStorage.removeItem("token");
        dispatch(resetQuerySlice());
        navigate("/login");
      });
  };

  useEffect(() => {
    handleCreateChannelInfo();
    handleFetchProfileData();
    handleBotSettingsData();
  }, []);

  useEffect(() => {
    const currMenuItem = MenuItemsList.filter(
      (obj) => obj.url === location.pathname
    );
    dispatch(setActivePageID(currMenuItem[0].id));
  }, [location]);

  const classes = useStyles();
  return (
    <>
      <Backdrop open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {!initialAppLoading && (
        <div className={classes.content}>
          <Navbar />

          <div
            className={clsx({
              [classes.mainSection]: true,
              noSidebar: !isSidebarOpened,
            })}
          >
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
