const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  basename: "",
  defaultPath: "/app/home",
  defaultPathID: "HOME",
  fontFamily: `'Ubuntu', sans-serif`,
  fontSize: 14,
  backgroundColor: "#111111",
  borderColor: "#111111",
  borderRadiusLarge: 15,
  borderRadius: 8,
  borderRadiusSmall: 4,
  gridSpacing: 3,
  gridSpacingSm: 2,
  imgFolderPath: "/static/img/",
};

export default config;
