import { createSlice, current } from "@reduxjs/toolkit";
import {
  convertUTCTimeToLocal,
  getLoadingBubbleObj,
} from "../../components/Chat/utils";

import CHAT_MSG from "../../mock-data/WAChatMessages.json";

const initialState = {
  welcomeMessage: {},
  userQuery: "",
  chatMessages: [],
  //chatMessages: CHAT_MSG,
  isBubbleLoading: false,
  chatSessionID: "",
  chatHisory: [],
  isShowLoadHistory: true,
  isHideChatForm: false,
  bubbleInputsData: {},
  isMicroBotOpened: false,
  askQueryToBot: "",
  isAdvisorLogged: false,
  isProspectLogged: false,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    setUserQuery: (state, { payload }) => {
      state.userQuery = payload;
    },
    setChatMessage: (state, { payload }) => {
      const { data, id } = payload;
      if (id === null) {
        state.chatMessages.push(data);
      } else {
        const { human, bot } = data;
        const index = state.chatMessages.findIndex((obj) => obj.id === id);
        if (index < 0) state.chatMessages.push(human, bot);
        else state.chatMessages.splice(index, 1, human, bot);
      }
    },
    loadChatHistory: (state, { payload }) => {
      let tempArr = [];
      payload.map((obj) => {
        let humanBubble = {},
          botBubble = {};
        humanBubble = {
          author: "human",
          body: obj["question"],
          date_time: convertUTCTimeToLocal(obj["question_received_timestamp"]),
          id: obj["question_id"],
          type: obj["question_type"],
        };
        botBubble = {
          ...obj.data,
          actions: [],
          author: "bot",
          date_time: convertUTCTimeToLocal(obj["response_received_timestamp"]),
        };
        tempArr.push(humanBubble, botBubble);
      });
      state.chatMessages.splice(0, 0, ...tempArr);
    },
    setBubbleLoading: (state) => {
      const loadingObj = getLoadingBubbleObj();
      //state.userQuery = "";
      state.isBubbleLoading = true;
      state.chatMessages.push(loadingObj);
    },
    removeBubbleLoading: (state, { payload }) => {
      const loadingIndex = state.chatMessages.findIndex(
        (obj) => obj.id === payload
      );
      if (loadingIndex > 0) {
        state.chatMessages.splice(loadingIndex + 1, 1);
        state.isBubbleLoading = false;
        return;
      }
    },
    clearActionButtons: (state) => {
      state.chatMessages[state.chatMessages.length - 1]["actions"] = [];
    },
    setChannelInfo: (state, { payload }) => {
      const { chat_session_id, chat, initial_message } = payload;
      const formattedData = JSON.parse(initial_message);

      let msgObj = {
        author: "bot",
        ...formattedData,
      };
      state.chatSessionID = chat_session_id;
      state.chatHisory = chat;
      state.welcomeMessage = { ...msgObj };
    },
    hideChatHistoryButton: (state) => {
      state.isShowLoadHistory = false;
    },
    setBubbleInputsData: (state, { payload }) => {
      state.bubbleInputsData = { ...state.bubbleInputsData, ...payload };
    },
    clearBubbleInputs: (state) => {
      state.chatMessages[state.chatMessages.length - 1]["body"]["input_data"] =
        {};
      state.bubbleInputsData = {};
    },
    setChatFormHide: (state, { payload }) => {
      state.isHideChatForm = payload;
    },
    setMicroBotOpened: (state, { payload }) => {
      state.isMicroBotOpened = payload;
    },
    setAskQueryToBot: (state, { payload }) => {
      state.askQueryToBot = payload;
    },
    setMicroBotClosed: (state) => {
      state.isMicroBotOpened = false;
      state.askQueryToBot = "";
      // state.chatSessionID = "";
      // state.chatHisory = [];
    },
    resetQuerySlice: (state) => {
      return initialState;
    },
    setUserTypeAsAdvisor: (state, { payload }) => {
      state.isAdvisorLogged = payload;
    },
    setUserTypeAsProspect: (state, { payload }) => {
      state.isProspectLogged = payload;
    },
  },
});

export const {
  setUserQuery,
  setChatMessage,
  loadChatHistory,
  setBubbleLoading,
  removeBubbleLoading,
  clearActionButtons,
  setChannelInfo: setQueryChannelInfo,
  hideChatHistoryButton,
  setBubbleInputsData,
  clearBubbleInputs,
  setChatFormHide,
  setMicroBotOpened,
  setAskQueryToBot,
  setMicroBotClosed,
  resetQuerySlice,
  setUserTypeAsAdvisor,
  setUserTypeAsProspect,
} = querySlice.actions;
export default querySlice.reducer;
