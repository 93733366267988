import React from "react";
import { ButtonBase } from "@mui/material";
import { useSelector } from "react-redux";

const NavLogo = () => {
  return (
    <ButtonBase
      disableRipple
      // onClick={() => openInNewTab("https://www.ongil.ai/")}
    >
      <img
        src={"/static/img/logo.jpg"}
        alt={"Capgemini"}
        style={{ width: "56px" }}
      />
    </ButtonBase>
  );
};

export default NavLogo;
