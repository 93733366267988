import moment from "moment";
import DOMPurify from "dompurify";

// Sanitize give string - Security check
export const sanitizeInput = (str) => {
  if (typeof str === "number") {
    const afterPurify = DOMPurify.sanitize(str);
    return afterPurify ? parseInt(afterPurify) : 0;
  }
  return DOMPurify.sanitize(str);
};

export const getMessageSentTime = (date) => {
  return moment(date).format("lll");
};

export const getCurrentDateTime = () => {
  return moment().format("MMM D YYYY LTS");
};

export const getHumanBubbleObj = (query, date, id, type = "text") => {
  return {
    author: "human",
    body: query ? query : "",
    id,
    type,
    date_time: date,
  };
};

export const getLoadingBubbleObj = () => {
  return {
    author: "bot",
    type: "loading",
  };
};

export const convertUTCTimeToLocal = (date_time) => {
  return moment.parseZone(date_time).local().format("MMM D YYYY LTS");
};

export const convertInputsDataToObj = (value, label) => {
  let tempObj = {};
  tempObj[sanitizeInput(label)] = value;
  return tempObj;
};

// Common Utils
// Convert React Select Options
export const convertToSelectOptions = (data) => {
  const Options = [];
  data.sort().map((item, index) => {
    Options.push({
      name: item,
      label: item,
      id: `${item}-${index}`,
    });
  });
  return Options;
};

export const capitalizeString = (str) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  else return "";
};

export const convertToLabel = (str) => {
  return capitalizeString(str ? str.replaceAll("_", " ") : "");
};

//  Convert time to Duration
export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " yrs";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hrs";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " mins";
  }
  return Math.floor(seconds) + " secs";
}

/**
 * Password validator for login pages
 */

// has number
const hasNumber = (value) => {
  return new RegExp(/[0-9]/).test(value);
};

// has mix of small and capitals
const hasMixed = (value) => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

// has special chars
const hasSpecial = (value) => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

// password strength indicator
export const strengthIndicator = (value) => {
  let strengths = 0;
  if (value.length > 5) strengths++;
  if (value.length > 7) strengths++;
  if (hasNumber(value)) strengths++;
  if (hasSpecial(value)) strengths++;
  if (hasMixed(value)) strengths++;
  return strengths;
};

/* Validator function */
export const validateString = (val) => {
  if (val && typeof val === "string" && val.length > 0) return true;
  else return false;
};

export const validateArrayData = (val) => {
  if (val && Array.isArray(val) && val.length > 0) return true;
  else return false;
};

export const validateObjectData = (val) => {
  if (val && typeof val === "object" && Object.keys(val).length > 0)
    return true;
  else return false;
};

export const sortArrayObjbyDate = (arr) => {
  return arr.sort((a, b) => new Date(b.x) - new Date(a.x));
};

//FIND which array has greater length compare to others
//-------------------------------------------
// let findLargeArr = [];

//   let uniqueFamilydata = [...new Set(report_list.map((d) => d["family"]))];
//   uniqueFamilydata.map((family) => {
//     let filteredData = report_list.filter((each) => each["family"] === family);

//     if (filteredData.length > findLargeArr.length) {
//       familyName = family;
//       findLargeArr = filteredData;
//     }
//   });

export function intToString(num) {
  if (typeof num !== "number") return num;

  const suffixes = ["", "K", "M", "B", "T", "P", "E"];
  const tier = (Math.log10(Math.abs(num)) / 3) | 0; // Determine the appropriate tier (thousands, millions, etc.)

  if (tier === 0) return num; // If the number is less than 1000, return num;

  const suffix = suffixes[sanitizeInput(tier)]; // Get the suffix for the determined tier
  const scale = Math.pow(10, tier * 3); // Calculate the scale factor

  const scaledNum = num / scale;
  const formattedNum = scaledNum.toFixed(2);

  const finalNum = formattedNum.endsWith(".00")
    ? formattedNum.slice(0, -3)
    : formattedNum;

  return finalNum + suffix;
}

// export function intToString(num) {
//   if (typeof num != "number") return num;
//   num = num.toString().replace(/[^0-9.]/g, "");
//   if (num < 1000) {
//     return num;
//   }
//   let si = [
//     { v: 1e3, s: "K" },
//     { v: 1e6, s: "M" },
//     { v: 1e9, s: "B" },
//     { v: 1e12, s: "T" },
//     { v: 1e15, s: "P" },
//     { v: 1e18, s: "E" },
//   ];
//   let index;
//   for (index = si.length - 1; index > 0; index--) {
//     if (num >= si[index].v) {
//       break;
//     }
//   }

//   // return (
//   //   (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
//   //   si[index].s
//   // );
//   return (
//     (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
//     si[index].s
//   );
// }

// Deboune
export const debounceInput = (fn, delay = 200) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(context, args);
    }, delay);
  };
};

// Get current domain url
export const getCurrentDomainUrl = (withProtocal = false) => {
  const protocal = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;
  const portCheck = port ? `:${port}` : "";

  let url = "";
  if (withProtocal) url = `${protocal}://${hostname}${portCheck}`;
  else url = `${hostname}${portCheck}`;

  return url;
};

// Format Bytes
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
    sizes[sanitizeInput(i)]
  }`;
};
